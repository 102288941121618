<template>
    <v-card
        class="mx-auto"
        :class="clickable ? 'clickable' : ''"
        max-width="219"
    >
        <div @click="prizeClick">
            <v-img
                v-if="prize.photo_url"
                :src="prize.photo_url"
                height="183px"
                class="card-image"
            ></v-img>
            <div v-else class="d-flex justify-center align-center media-placeholder grey lighten-2 card-placeholder">
                <v-icon large>fas fa-image</v-icon>
            </div>

            <div v-if="hasSlot('absolute')" class="prize-card-top-right-absolute">
                <slot name="absolute" v-bind="{ prize }"></slot>
            </div>

            <span v-if="!hidePrice" class="px-2 primary--text prize-card-bottom-right-absolute prize-value">{{ prize.value | currency }}</span>

            <v-divider></v-divider>

            <v-card-title class="d-flex pt-4 pb-4">
                <span class="prize-name">{{ prize.name }}</span>
            </v-card-title>
            <v-card-subtitle class="pb-2">
                <div class="mt-1">
                    <span v-if="prize.expires_in" class="caption prize-expires-in">{{ $t('views.Prizes.tabs.list.prize_card.expires_in', { expires_in: prize.expires_in }) }}</span>
                    <span v-else class="caption prize-expires-in">{{ $t('views.Prizes.tabs.list.prize_card.never_expires') }}</span>
                </div>
                <div v-if="prize.description">
                    <v-divider class="mt-3 mb-2"></v-divider>
                    <span class="prize-description">{{ prize.description }}</span>
                </div>
            </v-card-subtitle>
        </div>

        <template v-if="hasSlot('actions')">
            <v-divider></v-divider>

            <v-card-actions>
                <slot name="actions" v-bind="{ prize }"></slot>
            </v-card-actions>
        </template>
    </v-card>
</template>

<script>
import Prize   from '../../models/Prize'
import HasSlot from '../../mixins/HasSlot'

export default {
    name: 'SharedPrizeCard',
    mixins: [ HasSlot ],
    props: {
        prize: {
            type: Prize,
            required: true,
        },
        clickable: {
            type: Boolean,
            required: false,
            defualt: false,
        },

        // Texto personalizado para o preço deste prêmio
        priceText: {
            type: String,
            required: false,
        },

        // Indica se deve esconder o preço deste prêmio
        hidePrice: Boolean,
    },
    methods: {
        onFavorite() {
            this.$emit('favorite')
        },
        prizeClick() {
            if(!this.clickable) return

            this.$emit('on-prize-click', this.prize)
        }
    },
    computed: {
        computedPriceText() {
            return this.priceText || this.$options.filters.currency(this.prize.value);
        },
    },
}
</script>

<style lang="scss" scoped>
.media-placeholder {
    height: 250px;
}

.prize-card-top-right-absolute {
    position: absolute;
    top     : .25rem;
    right   : .25rem;
}

.prize-card-bottom-right-absolute {
    position: absolute;
    top     : 9.8rem;
    right   : .25rem;
}

.prize-name, .prize-value {
    font-size: 14px;
}

.prize-name {
    line-height: 15px;
}

.prize-value {
    background-color: $background;
    border-radius: 8px !important;
}

.prize-description, .prize-expires-in {
    font-size: 12px;
}

.card-placeholder {
    height: 183px;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px !important;
}

.card-image, .card-placeholder {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}
</style>