var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mx-auto",
      class: _vm.clickable ? "clickable" : "",
      attrs: { "max-width": "219" }
    },
    [
      _c(
        "div",
        { on: { click: _vm.prizeClick } },
        [
          _vm.prize.photo_url
            ? _c("v-img", {
                staticClass: "card-image",
                attrs: { src: _vm.prize.photo_url, height: "183px" }
              })
            : _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-center align-center media-placeholder grey lighten-2 card-placeholder"
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("fas fa-image")
                  ])
                ],
                1
              ),
          _vm.hasSlot("absolute")
            ? _c(
                "div",
                { staticClass: "prize-card-top-right-absolute" },
                [_vm._t("absolute", null, null, { prize: _vm.prize })],
                2
              )
            : _vm._e(),
          !_vm.hidePrice
            ? _c(
                "span",
                {
                  staticClass:
                    "px-2 primary--text prize-card-bottom-right-absolute prize-value"
                },
                [_vm._v(_vm._s(_vm._f("currency")(_vm.prize.value)))]
              )
            : _vm._e(),
          _c("v-divider"),
          _c("v-card-title", { staticClass: "d-flex pt-4 pb-4" }, [
            _c("span", { staticClass: "prize-name" }, [
              _vm._v(_vm._s(_vm.prize.name))
            ])
          ]),
          _c("v-card-subtitle", { staticClass: "pb-2" }, [
            _c("div", { staticClass: "mt-1" }, [
              _vm.prize.expires_in
                ? _c("span", { staticClass: "caption prize-expires-in" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("views.Prizes.tabs.list.prize_card.expires_in", {
                          expires_in: _vm.prize.expires_in
                        })
                      )
                    )
                  ])
                : _c("span", { staticClass: "caption prize-expires-in" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "views.Prizes.tabs.list.prize_card.never_expires"
                        )
                      )
                    )
                  ])
            ]),
            _vm.prize.description
              ? _c(
                  "div",
                  [
                    _c("v-divider", { staticClass: "mt-3 mb-2" }),
                    _c("span", { staticClass: "prize-description" }, [
                      _vm._v(_vm._s(_vm.prize.description))
                    ])
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _vm.hasSlot("actions")
        ? [
            _c("v-divider"),
            _c(
              "v-card-actions",
              [_vm._t("actions", null, null, { prize: _vm.prize })],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }